import React from "react";
import FeedItemDate from "./FeedItemDate";
import { FeedIgTv } from "../hooks/feed-items";
import { SelectIgTvCallback, T } from "./AppProps";

type FeedItemTvProps = {
  data: FeedIgTv;
  selectIgTv: SelectIgTvCallback;
  t: T;
  measure: () => void;
};

const FeedItemTv: React.FC<FeedItemTvProps> = ({
  data: { metadata },
  measure,
  selectIgTv,
  t,
}) => (
    <div
      className="feed-item feed-item--tv"
      onClick={selectIgTv}
      data-testid={`feed-item-${metadata.source}`}
    >
      <div className="feed-item_section feed_ig-tv">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <span onLoad={measure} className="feed-item_media-ig-tv" />

        <div className="feed-item_header-titles">
          <span
            className="feed-item_header-title"
            data-automation="feed-item-ig-tv-title"
            title={t("ig-tv.title")}
          >
            <div
              className="feed-item_icon feed-item_icon--ig-tv"
              data-automation="feed-item-ig-tv-icon"
            />
            {t("ig-tv.title")}
          </span>
          <span
            className="feed-item_ig-tv-subtitle"
            data-automation="feed-item-ig-tv-subtitle"
          >
            {t("ig-tv.ON_AIR")}
          </span>
        </div>
      </div>

      <FeedItemDate timestamp={metadata.eventTimestamp} />
    </div>
  );

export default FeedItemTv;
