import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

console.log("ig-wpx-feed", { React, ReactDOM, singleSpaReact });

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    console.error(err, info, props);
    return <div>An error has occurred, please reload.</div>;
  },
});

export type { default as AppProps } from "./components/AppProps";

export const { bootstrap, mount, unmount, update } = lifecycles;
