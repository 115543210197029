import React from "react";
import FeedItemDate from "./FeedItemDate";
import { FeedAlert } from "../hooks/feed-items";
import { SelectMarketCallback, T } from "./AppProps";

type KeyValuePair = { [key: string]: string };

const FEED_TEXT: KeyValuePair = {
  FIVE_MINUTES: "alert.price-change-alert.five-minutes",
  ONE_HOUR: "alert.price-change-alert.one-hour",
  ONE_DAY: "alert.price-change-alert.one-day",
};

const CHANGE_TYPE: KeyValuePair = {
  PERCENT: "alert.percent",
  POINTS: "alert.points",
};

const DIRECTION: KeyValuePair = {
  "alert.change.up": "alert.up",
  "alert.change.down": "alert.down",
};

type FeedItemPriceChangeAlertProps = {
  data: FeedAlert;
  selectMarket: SelectMarketCallback;
  t: T;
};

const FeedItemPriceChangeAlert: React.FC<FeedItemPriceChangeAlertProps> = ({
  data: { content, metadata },
  selectMarket,
  t,
}) => {
  const { value } = content;
  const direction = t(DIRECTION[content.wording]);
  const changeType = t(CHANGE_TYPE[content.changeType]);
  const feedKeyKey = FEED_TEXT[content.timePeriod];
  const wording = t(feedKeyKey, {
    changeType,
    value,
    direction,
  });

  return (
    <div
      className="feed-item feed-item--price-alert"
      onClick={() => selectMarket(content.epic)}
      data-testid={`feed-item-${metadata.source}`}
    >
      <div className="feed-item_header">
        <span
          className="feed-item_icon transaction-state_icon transaction-state_icon--price-alert"
          data-automation="feed-item-price-change-alert-icon"
        />
        <span
          className="feed-item_header-title"
          data-automation="feed-item-price-change-alert-title"
          title={content.market}
        >
          {content.market}
        </span>
        <FeedItemDate timestamp={metadata.eventTimestamp} />
      </div>
      <div className="feed-item_section">
        <div
          className="feed-item_status"
          data-automation="feed-item-price-change-alert-status"
        >
          {wording}
        </div>
      </div>
    </div>
  );
};

export default FeedItemPriceChangeAlert;
