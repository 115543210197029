import React, { useEffect, useMemo, useReducer } from "react";
import get from "lodash.get";
import { useLightstreamer } from "@ig-wpx/lightstreamer";
import reducer, { Action, AppState } from "./feed-reducer";
import { Filter, FILTERS } from "../utils/constants";
import { FeedItem } from "./feed-items";
import createAudioPlayer from "../utils/audio-player";
import shouldSkipSnapshot from "../utils/should-skip-snapshot";
import { useSession } from "./use-session";

const FEED_ITEMS = "/usermanagement/feed";

const SITE_IDS_DEFAULT_TO_ENGLISH_FEED = [
  "inm-en-GB",
  "inm-zh-CN",
  "cnm-zh-CN",
  "isa-en-GB",
  "iet-en-GB",
  "sip-en-GB",
];

/**
 * This is a subset of siteIDs for which the streaming keys site ID in the format {siteId} should
 * be 'IGI'
 * Again this can be removed once TDRK-2573 is implemented by MW and this logic will be removed
 * from WTP.
 */

const SITE_IDS_STREAMING_TO_IGI = ["sip", "isa"];

/**
 * This is a subset of siteIDs for which the streaming key should be in the format {SiteID-locale}
 * e.g: CHM-DE_DE for the CHM German feed as opposed to simply {SiteID} as normally used.
 */

const MULTI_LOCALE_SITE_IDS = ["chm", "inm"];

// filter out unwanted feed items
const includeItem = (isIgTvDisallowed: boolean) => (item: FeedItem) =>
  item &&
  !(isIgTvDisallowed && get(item, "metadata.source") === "IG_TV") &&
  get(item, "content.reportType") !== "EDIT_BOOKCOST";

// Skip the first snapshot update for each subscription (see INC0460191 and INC0624603)
const skipSnapshot = (
  isIgTvDisallowed: boolean,
  subscriptionTimestamp: number,
  dispatch: React.Dispatch<Action>
) => {
  const include = includeItem(isIgTvDisallowed);
  return ({ CONFIRMS: confirm }: { [key: string]: string }) => {
    const data = JSON.parse(confirm);
    if (!shouldSkipSnapshot(subscriptionTimestamp) && include(data)) {
      dispatch({
        type: "ADD_ITEM",
        data,
      });
    }
  };
};

const origins: Record<string, string> = {
  mirage: "https://mirage-deal.ig.com",
  test: "https://net-deal.ig.com",
  uat: "https://web-deal.ig.com",
  demo: "https://demo-deal.ig.com",
  prod: "https://deal.ig.com",
} as const;

type DataState = {
  loadNextPage(nextCursor?: string): void;
  changeFilter(filter: Filter): void;
  activateBuffer(useBuffer: boolean): void;
  clearBuffer(): void;
  removeGrowl(growl: FeedItem): void;
  removeUrgentAlert(): void;
} & AppState;

const useFeed = ({
  defaultFilter,
  isIgTvDisallowed = false,
  areIgTvNotificationsEnabled = false,
  isFeedVisible,
  shouldPlaySounds,
}: {
  areIgTvNotificationsEnabled?: boolean;
  defaultFilter: Filter;
  isFeedVisible: boolean;
  isIgTvDisallowed?: boolean;
  shouldPlaySounds: boolean;
}): DataState => {
  const {
    webSiteId: siteId,
    env,
    cst,
    xst,
    currentAccountId: accountId,
    clientLocale: locale,
  } = useSession();

  const apiHost: string = env in origins ? origins[env] : origins.prod;
  const audioPlayer = useMemo(
    () => (shouldPlaySounds ? createAudioPlayer() : undefined),
    [shouldPlaySounds]
  );

  const [state, dispatch] = useReducer(reducer, {
    audioPlayer,
    isLoading: true,
    data: [],
    buffer: [],
    growls: [],
    error: null,
    cursor: "",
    filter: defaultFilter,
    pageSize: 50,
    igTvDisallowed: isIgTvDisallowed,
    igTvNotificationsEnabled: areIgTvNotificationsEnabled,
    isFeedVisible,
    useBuffer: false,
  });
  // const [subscriptionsLoaded, setSubscriptionsLoaded] = useState(false);
  const { cursor, filter, igTvDisallowed, pageSize } = state;
  // TODO:
  const { subscribe, unsubscribe } = useLightstreamer();

  useEffect(() => {
    dispatch({ type: "TOGGLE_SOUNDS", audioPlayer });
  }, [audioPlayer]);

  useEffect(() => {
    dispatch({ type: "TOGGLE_FEED_VISIBILITY", isFeedVisible });
  }, [isFeedVisible]);

  useEffect(() => {
    dispatch({ type: "TOGGLE_IG_TV", isIgTvDisallowed });
  }, [isIgTvDisallowed]);

  useEffect(() => {
    if (!isFeedVisible) {
      return;
    }
    const clientSiteIdLocale = `${siteId}-${locale}`;
    const shouldFallbackToEnglish =
      SITE_IDS_DEFAULT_TO_ENGLISH_FEED.includes(clientSiteIdLocale);

    const params = new URLSearchParams({
      siteId: shouldFallbackToEnglish ? "ENG" : siteId.toUpperCase(),
      size: `${pageSize}`,
      locale: shouldFallbackToEnglish
        ? "EN_GB"
        : locale.replace("-", "_").toUpperCase(),
    });
    FILTERS[filter].params.forEach((f) => params.append("filter", f));
    if (cursor) {
      params.append("before", cursor);
    }
    const url = `${apiHost}${FEED_ITEMS}/${accountId}?${params}`;
    const controller = new AbortController();
    (async () => {
      try {
        const response = await fetch(url, {
          signal: controller.signal,
          headers: {
            "content-type": "application/json; charset=UTF-8",
            cst,
            "x-security-token": xst,
          },
        });
        if (!response.ok) {
          dispatch({ type: "ERROR", error: response.statusText });
          return;
        }
        const data = (await response.json()).filter(
          includeItem(igTvDisallowed)
        );
        dispatch({ type: "LOADING_COMPLETE", data });
      } catch (error: any) /* istanbul ignore next */ {
        if (error.name !== "AbortError") {
          dispatch({ type: "ERROR", error });
        }
      }
    })();
    // eslint-disable-next-line consistent-return
    return () => controller && controller.abort();
  }, [
    accountId,
    apiHost,
    cst,
    cursor,
    filter,
    pageSize,
    siteId,
    xst,
    locale,
    igTvDisallowed,
    isFeedVisible,
  ]);

  useEffect(() => {
    if (!subscribe) {
      return;
    }
    const shouldFallbackToIGI = SITE_IDS_STREAMING_TO_IGI.includes(siteId);
    const selectedSiteId = shouldFallbackToIGI ? "IGI" : siteId.toUpperCase();
    const streamingKeys = FILTERS[Filter.ALL].streamingKeys({
      accountId,
      siteId: MULTI_LOCALE_SITE_IDS.includes(siteId)
        ? `${siteId}-${locale.replace("-", "_")}`.toUpperCase()
        : selectedSiteId,
      isIgTvDisallowed,
    });
    const subscription = subscribe(
      "DISTINCT",
      streamingKeys,
      ["CONFIRMS"],
      skipSnapshot(isIgTvDisallowed, Date.now(), dispatch)
    );
    // eslint-disable-next-line consistent-return
    return () => subscription && unsubscribe && unsubscribe(subscription);
  }, [accountId, siteId, subscribe, unsubscribe, locale, isIgTvDisallowed]);

  return {
    loadNextPage: (nextCursor) =>
      dispatch({ type: "LOAD_NEXT_PAGE", cursor: nextCursor }),
    changeFilter: (f) => dispatch({ type: "CHANGE_FILTER", filter: f }),
    activateBuffer: (useBuffer) =>
      dispatch({ type: "ACTIVATE_BUFFER", useBuffer }),
    clearBuffer: () => dispatch({ type: "CLEAR_BUFFER" }),
    removeGrowl: (growl) => dispatch({ type: "REMOVE_GROWL", growl }),
    removeUrgentAlert: () => dispatch({ type: "REMOVE_URGENT_ALERT" }),
    ...state,
  };
};

export default useFeed;
