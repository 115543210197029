import React, { createContext, useContext } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Account, Client } from "@ig-wpx/shell/types";

interface ISessionContext extends Client {
  currentAccount: Account;
}

type SessionProps = {
  children: React.ReactNode;
  client: Client;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SessionContext = createContext<ISessionContext>(undefined!);

export const SessionProvider: React.FC<SessionProps> = ({
  children,
  client,
}) => (
    <SessionContext.Provider
      value={{
        ...client,
        currentAccount: client?.accounts.find(
          ({ accountId }) => accountId === client.currentAccountId
        ),
      }}
    >
      {children}
    </SessionContext.Provider>
  );

export const useSession = () => useContext(SessionContext);
