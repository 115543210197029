import React from "react";
import classNames from "classnames";
import FeedItemDate from "./FeedItemDate";
// import { T } from "@iggroup/react-scaffold/src/hooks/use-l10n";
import { FeedConfirm, Confirm } from "../hooks/feed-items";
import { SelectMarketCallback, T } from "./AppProps";
import { useSession } from "../hooks/use-session";

type FeedItemConfirmProps = {
  data: FeedConfirm;
  t: T;
  selectMarket: SelectMarketCallback;
};

const getStatus = (status: string, t: T): string => {
  switch (status) {
    case "Close":
      return t("closed");
    case "Partial Close":
      return t("partially-closed");
    case "Open":
      return t("opened");
    default:
      return status ? status.toLowerCase() : /* istanbul ignore next */ "";
  }
};

const getTitle = (
  content: Confirm,
  status: string,
  isPhysical: boolean,
  t: T
): string => {
  let title = `${t(
    isOrder(content, isPhysical) ? "order" : "position"
  )} ${status}`;
  if (content.reportType === "REJECTION") {
    title = t("rejection");
  } else if (content.reportType === "PARTIAL_FILL") {
    title = t("partially-filled");
  } else if (content.reportType === "ACKNOWLEDGE") {
    title = t("new-order");
  } else if (content.executionInfo.origin === "SYSTEM") {
    title = t("system-trade", { status: title });
  }
  if (isPhysical) {
    if (content.reportType === "CANCELED") {
      title = t("order-cancellation");
    } else if (content.reportType === "REJECTION") {
      title = t("order-rejection");
    } else if (content.reportType === "ACKNOWLEDGE") {
      title = t("order-received");
    } else if (content.reportType === "PARTIAL_FILL") {
      title = t("order-partial-fill");
    } else if (content.reportType === "FULL_FILL") {
      title = t("order-full-fill");
    } else if (content.reportType === "EDIT") {
      title = t("order-edit");
    }
  }
  return title;
};

const isOrder = (content: Confirm, isPhysical: boolean): boolean =>
  isPhysical || !!content.reportData.expiryTime;

const getType = (content: Confirm, isPhysical: boolean) => {
  const dealType = isOrder(content, isPhysical) ? "order" : "position";
  const isRejected = content.reportType === "REJECTION";
  let status = "confirmed";

  if (content.reportType === "PARTIAL_FILL") {
    status = "confirmed-partial";
  }
  if (isRejected) {
    status = "rejected";
  }

  if (isPhysical) {
    if (content.reportType === "PARTIAL_FILL") {
      return "physical-confirmed-partial";
    }
    if (content.reportType === "FULL_FILL") {
      return "physical-full-fill";
    }
    if (isRejected) {
      return "physical-rejected";
    }
    if (content.reportType === "CANCELED") {
      return "physical-cancelled";
    }
    if (content.reportType === "EDIT") {
      return "physical-edit";
    }
  }
  return `${dealType}-${status}`;
};

const getProfitLossStyle = (profitValue: string): string => {
  if (profitValue.includes("-")) {
    return "numeric-value numeric-value-in-loss";
  }
  if (/[1-9]/g.test(profitValue)) {
    return "numeric-value numeric-value-in-profit";
  }
  return "numeric-value numeric-value-neutral";
};

const FeedItemConfirm: React.FC<FeedItemConfirmProps> = ({
  data: { content, metadata },
  t,
  selectMarket,
}) => {
  const {
    currentAccount: { productCode },
    siteType,
  } = useSession();
  const isPhysical = siteType === "PHYSICAL";
  const isMTF = isPhysical && productCode === "MTFTURBO";

  const dasherizedChannelType = content.executionInfo.channelType
    .toLowerCase()
    .replace(/_/g, "-");
  const status = getStatus(content.reportData.status?.value, t);
  const title = getTitle(content, status, isPhysical, t);
  const isRejection = content.reportType === "REJECTION";
  const limitLevel =
    content.reportData.limitLevel?.value ||
    content.reportData.limitDistance?.value;
  const stopLevel =
    content.reportData.stopLevel?.value ||
    content.reportData.stopDistance?.value;
  const className = classNames(
    "feed-item",
    "feed-item--confirm",
    dasherizedChannelType,
    {
      "system-trade": content.executionInfo.origin === "SYSTEM",
      "working-order":
        content.executionInfo.channelType === "USER_CHANNEL" &&
        content.reportData.expiryTime,
      "partial-fill": content.reportType === "PARTIAL_FILL",
      rejection: isRejection,
      edit: content.reportType === "EDIT",
      cancellation: content.reportType === "CANCELED",
      "feed-item--no-hover": isMTF,
    }
  );
  const type = getType(content, isPhysical);
  const iconClassName = classNames(
    "feed-item_icon",
    "transaction-state_icon",
    `transaction-state_icon--${type}`
  );

  const period = content.reportData.period?.value;
  return (
    <div
      className={className}
      onClick={() => selectMarket(content.epic)}
      data-testid={`feed-item-${metadata.source}`}
    >
      <div className="feed-item_base-info">
        <span className={iconClassName} data-automation="feed-item-icon" />
        <div className="feed-item_headers">
          <div className="feed-item_header">
            <span
              className="feed-item_header-title"
              data-automation="feed-item-confirm-title"
            >
              {title}
            </span>
            <FeedItemDate timestamp={metadata.eventTimestamp} />
          </div>
          <div className="feed-item_header">
            <span
              className="feed-item_header-subtitle"
              title={content.reportData.marketName.value}
              data-automation="feed-item-confirm-subtitle"
            >
              {content.reportData.marketName.value}
            </span>
            {!isRejection && period && period !== "DFB" && period !== "-" && (
              <span
                className="feed-item_header-period"
                data-automation="feed-item-confirm-period"
              >
                {period}
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="feed-item_section">
        {isRejection && (
          <span
            className="feed-item_rejection"
            data-automation="feed-item-confirm-rejection"
          >
            {content.reportData.rejectionMessage.body}
          </span>
        )}
        {!isRejection && content.reportData.quantity && (
          <span
            className="feed-item_quantity"
            data-automation="feed-item-confirm-quantity"
          >
            {isPhysical && content.reportData.direction?.value}{" "}
            {content.reportData.quantity.value}
          </span>
        )}
        {!isRejection && content.reportData.orderLevel && (
          <span
            className="feed-item_orderLevel"
            data-automation="feed-item-confirm-order-level"
          >
            {" "}
            {t("at")} {content.reportData.orderLevel.value}
          </span>
        )}
        {!isRejection && isPhysical && content.reportData.orderType && (
          <div className="feed-item_orderType">
            {content.reportData.orderType.value}
          </div>
        )}
        {!isRejection && content.reportData.knockoutLevel && (
          <div className="feed-item_knockoutLevel">
            {content.reportData.knockoutLevel.label}:{" "}
            {content.reportData.knockoutLevel.value}
          </div>
        )}
        {!isRejection && content.reportData.profitLoss && (
          <div
            className="feed-item_profitLoss"
            data-automation="feed-item-confirm-profit-loss"
          >
            {content.reportData.profitLoss.label}:{" "}
            <span
              className={getProfitLossStyle(
                content.reportData.profitLoss.value
              )}
            >
              {content.reportData.profitLoss.value}
            </span>
          </div>
        )}
        {!isRejection && stopLevel && (
          <div
            className="feed-item_stopLevel"
            data-automation="feed-item-confirm-stop-level"
          >
            {t("stop")}: <span className="numeric-value">{stopLevel}</span>
            {content.trailingStop && (
              <span className="feed-item_trailingStop">
                , {content.reportData.stopType.value}
              </span>
            )}
          </div>
        )}
        {!isRejection && limitLevel && (
          <div
            className="feed-item_limitLevel"
            data-automation="feed-item-confirm-limit"
          >
            {t("limit")}: <span className="numeric-value">{limitLevel}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedItemConfirm;
