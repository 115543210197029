function resolveTranslations(packageName: string) {
  return window.location.hostname === "localhost"
    ? "http://localhost:3001/assets/locales/{{lng}}.json"
    : window.System.resolve(packageName).replace(
        /[^/]+$/,
        "public/assets/locales/{{lng}}.json"
      );
}

export default resolveTranslations;
