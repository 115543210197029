import get from "lodash.get";
import uniqby from "lodash.uniqby";
import {
  Filter,
  FILTERS,
  SOUND_PLAYING_SOURCES,
  UrgentAlertPrefix,
} from "../utils/constants";
import { FeedConfirm, FeedIgTv, FeedItem, FeedTwitter } from "./feed-items";

// Any screen names added to this array MUST be in lower case
const URGENT_ALERTERS = [
  "igclienthelp",
  "igespana",
  "igfrance",
  "igitalia",
  "ignederland",
  "ignorge",
  "igbank",
  "igdeutschland",
  "igsverige",
  "igjapan",
  "igtv",
  "igmena",
  "igaus",
  "ig_singapore",
];

const ONE_DAY = 24 * 3600 * 1000;

export type AppState = {
  audioPlayer?: HTMLAudioElement;
  buffer: FeedItem[];
  cursor?: string;
  data: FeedItem[];
  error: Error | string | null;
  filter: Filter;
  growls: FeedItem[];
  hasNextPage?: boolean;
  igTvDisallowed: boolean;
  igTvNotificationsEnabled: boolean;
  isFeedVisible: boolean;
  isLoading: boolean;
  pageSize: number;
  urgentAlert?: FeedTwitter;
  useBuffer: boolean;
};

export type Action =
  | { type: "ACTIVATE_BUFFER"; useBuffer: boolean }
  | { type: "ADD_ITEM"; data: FeedItem }
  | { type: "CHANGE_FILTER"; filter: Filter }
  | { type: "CLEAR_BUFFER" }
  | { type: "ERROR"; error: Error | string }
  | { type: "LOADING_COMPLETE"; data: FeedItem[] }
  | { type: "LOAD_NEXT_PAGE"; cursor?: string }
  | { type: "REMOVE_GROWL"; growl: FeedItem }
  | { type: "REMOVE_URGENT_ALERT" }
  | { type: "TOGGLE_FEED_VISIBILITY"; isFeedVisible: boolean }
  | { type: "TOGGLE_IG_TV"; isIgTvDisallowed: boolean }
  | { type: "TOGGLE_SOUNDS"; audioPlayer?: HTMLAudioElement };

const isItemTV = (item: FeedItem): boolean =>
  get(item, "metadata.source") === "IG_TV";
const isItemNotTV = (item: FeedItem): boolean =>
  get(item, "metadata.source") !== "IG_TV";

const isMostRecentUrgentAlert = (
  item: FeedItem,
  existingUrgentAlert?: FeedTwitter
) => (
    (!existingUrgentAlert ||
      item.metadata.eventTimestamp >
        existingUrgentAlert.metadata.eventTimestamp) &&
    item.metadata.eventTimestamp * 1000 + ONE_DAY > Date.now()
  );

const checkForUrgentAlert = (
  item: FeedItem,
  existingUrgentAlert?: FeedTwitter
) => {
  const { content } = item as FeedTwitter;
  let isUrgentAlert = false;
  let latestUrgentAlert = existingUrgentAlert;
  if (content.text) {
    const text = content.text.trim();
    if (
      URGENT_ALERTERS.includes(content.user.screenName.toLowerCase()) &&
      (text.startsWith(UrgentAlertPrefix.SHOW) ||
        text.startsWith(UrgentAlertPrefix.HIDE))
    ) {
      isUrgentAlert = true;
      latestUrgentAlert = isMostRecentUrgentAlert(item, existingUrgentAlert)
        ? (item as FeedTwitter)
        : existingUrgentAlert;
    }
  }
  return { isUrgentAlert, latestUrgentAlert };
};

const filterUrgentAlerts = (
  data: FeedItem[],
  filter: Filter,
  existingUrgentAlert?: FeedTwitter
) => {
  if (filter === Filter.NOTIFICATIONS) {
    return { data, urgentAlert: existingUrgentAlert };
  }
  return data.reduce<{
    data: FeedItem[];
    urgentAlert?: FeedTwitter;
  }>(
    (acc, item) => {
      const { isUrgentAlert, latestUrgentAlert } = checkForUrgentAlert(
        item,
        acc.urgentAlert
      );
      if (!isUrgentAlert) {
        acc.data.push(item);
      }
      acc.urgentAlert = latestUrgentAlert;
      return acc;
    },
    { data: [], urgentAlert: existingUrgentAlert }
  );
};

const shouldGrowl = (
  item: FeedItem,
  filter: Filter,
  igTvNotificationsEnabled: boolean,
  isFeedVisible: boolean
) => {
  const { source } = item.metadata;
  const isGrowlableSource =
    source === "PRICE_ALERT" ||
    source === "INDICATOR_ALERT" ||
    source === "PRICE_CHANGE_ALERT" ||
    (source === "IG_TV" &&
      (item as FeedIgTv).content.event === "ON_AIR" &&
      igTvNotificationsEnabled) ||
    (source === "ORDER_NOTIFICATION" &&
      (item as FeedConfirm).content.executionInfo.origin === "SYSTEM");
  return (!isFeedVisible || filter === Filter.TWEETS) && isGrowlableSource;
};

const maybePlaySound = (item: FeedItem, audioPlayer?: HTMLAudioElement) => {
  const shouldPlaySound =
    SOUND_PLAYING_SOURCES.includes(item.metadata.source) ||
    (item as FeedConfirm).content.channel === "OSAutoStopFill";
  if (!audioPlayer || !shouldPlaySound) {
    return;
  }
  audioPlayer.currentTime = 0;
  audioPlayer.play();
};

const dropDuplicates = (items: FeedItem[]): FeedItem[] => {
  // Dedupe item by metadata.id as we occasionally get duplicates
  const noDuplicateItemsById = uniqby(items, "metadata.id");

  // Check if there are two IGTV items, if not, return list
  const index = noDuplicateItemsById.findIndex(isItemTV);
  if (index === -1) {
    return noDuplicateItemsById;
  }

  // Slice out one of the IGTV's if there are more than 1
  return [
    ...noDuplicateItemsById.slice(0, index + 1),
    ...noDuplicateItemsById
      .slice(index + 1, noDuplicateItemsById.length)
      .filter(isItemNotTV),
  ];
};

const feedReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case "LOAD_NEXT_PAGE":
      return {
        ...state,
        error: null,
        cursor: action.cursor,
      };
    case "CHANGE_FILTER":
      return {
        ...state,
        data: [],
        buffer: [],
        isLoading: true,
        error: null,
        filter: action.filter,
        cursor: "",
      };
    case "LOADING_COMPLETE": {
      const { data, urgentAlert } = filterUrgentAlerts(
        action.data,
        state.filter,
        state.urgentAlert
      );
      return {
        ...state,
        isLoading: false,
        data: dropDuplicates([...state.data, ...data]),
        hasNextPage: action.data.length === state.pageSize,
        urgentAlert,
      };
    }
    case "ADD_ITEM": {
      const { isUrgentAlert, latestUrgentAlert } = checkForUrgentAlert(
        action.data,
        state.urgentAlert
      );
      maybePlaySound(action.data, state.audioPlayer);
      const shouldInclude =
        !isUrgentAlert &&
        FILTERS[state.filter].params.includes(action.data.metadata.source);
      const growls = shouldGrowl(
        action.data,
        state.filter,
        state.igTvNotificationsEnabled,
        state.isFeedVisible
      )
        ? [action.data, ...state.growls]
        : state.growls;
      const arrayNameToUpdate = state.useBuffer ? "buffer" : "data";
      const updates = shouldInclude
        ? {
            [arrayNameToUpdate]: dropDuplicates([
              action.data,
              ...state[arrayNameToUpdate],
            ]),
          }
        : {};
      return {
        ...state,
        growls,
        urgentAlert: latestUrgentAlert,
        ...updates,
      };
    }
    case "ACTIVATE_BUFFER":
      return {
        ...state,
        useBuffer: action.useBuffer,
      };
    case "CLEAR_BUFFER":
      return {
        ...state,
        useBuffer: false,
        buffer: [],
        data: dropDuplicates([...state.buffer, ...state.data]),
      };
    case "REMOVE_GROWL":
      return {
        ...state,
        growls: state.growls.filter((item) => item !== action.growl),
      };
    case "REMOVE_URGENT_ALERT":
      return {
        ...state,
        urgentAlert: undefined,
      };
    case "TOGGLE_FEED_VISIBILITY":
      return {
        ...state,
        isFeedVisible: action.isFeedVisible,
      };
    case "TOGGLE_IG_TV":
      return {
        ...state,
        data: [],
        buffer: [],
        isLoading: true,
        error: null,
        cursor: "",
        igTvDisallowed: action.isIgTvDisallowed,
      };
    case "TOGGLE_SOUNDS":
      return {
        ...state,
        audioPlayer: action.audioPlayer,
      };
    case "ERROR":
      console.error(action.error);
      return {
        ...state,
        error: action.error,
      };
    /* istanbul ignore next */
    default:
      return state;
  }
};

export default feedReducer;
