import React from "react";
import FeedItemDate from "./FeedItemDate";
import { FeedAlert } from "../hooks/feed-items";
import { SelectMarketCallback } from "./AppProps";

type FeedItemIndicatorAlertProps = {
  data: FeedAlert;
  selectMarket: SelectMarketCallback;
};

const FeedItemIndicatorAlert: React.FC<FeedItemIndicatorAlertProps> = ({
  data: { content, metadata },
  selectMarket,
}) => (
    <div
      className="feed-item feed-item--indicator-alert"
      onClick={() => selectMarket(content.epic)}
      data-testid={`feed-item-${metadata.source}`}
    >
      <div className="feed-item_header">
        <span
          className="feed-item_icon transaction-state_icon transaction-state_icon--indicator-alert"
          data-automation="feed-item-indicator-alert-icon"
        />
        <span
          className="feed-item_header-title"
          data-automation="feed-item-indicator-alert-title"
          title={content.market}
        >
          {content.market}
        </span>
        <FeedItemDate timestamp={metadata.eventTimestamp} />
      </div>
      <div className="feed-item_section">
        <div
          className="feed-item_status"
          data-automation="feed-item-indicator-alert-status"
        >
          {content.wording}
        </div>
        {content.message}
      </div>
    </div>
  );

export default FeedItemIndicatorAlert;
