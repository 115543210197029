/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import twitter from "twitter-text";
import { FeedTwitter, TwitterUrl } from "../hooks/feed-items";
import { UrgentAlertPrefix } from "../utils/constants";
import { useSession } from "../hooks/use-session";

type UrgentAlertProps = {
  alert: FeedTwitter;
  onDismiss: () => void;
};

const getFormattedTweetText = (
  text: string,
  /* istanbul ignore next */ urls: TwitterUrl[] = []
) => {
  const urlEntities = urls.map((entity) => ({
      display_url: entity.displayUrl,
      expanded_url: entity.expandedUrl,
      ...entity,
    }));

  return twitter.autoLink(twitter.htmlEscape(text), {
    targetBlank: true,
    // @ts-ignore
    urlEntities,
  });
};

const FeedItemTweet: React.FC<UrgentAlertProps> = ({
  alert: { content, metadata },
  onDismiss,
}) => {
  // TODO: const { formatFullDate } = useL10n();
  const { clientLocale } = useSession();
  const dateFormatter = new Intl.DateTimeFormat(
    clientLocale.replace("_", "-"),
    { dateStyle: "long" }
  );
  const text = content.text.trim();
  if (!text.startsWith(UrgentAlertPrefix.SHOW)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }
  const message = getFormattedTweetText(
    text.substring(UrgentAlertPrefix.SHOW.length),
    content.urls
  );
  return (
    <div className="urgent-alert">
      <p>
        {dateFormatter.format(metadata.eventTimestamp)} {message}
      </p>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className="urgent-alert_close"
        data-testid="close-urgent-alert"
        onClick={onDismiss}
      />
    </div>
  );
};

export default FeedItemTweet;
