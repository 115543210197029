// TODO - don't just return test here, do the smart env checking

export const ORIGINS = [
  "https://net-deal.ig.com",
  "https://web-deal.ig.com",
  "https://demo-deal.ig.com",
  "https://deal.ig.com",
] as const;

export type Origin = (typeof ORIGINS)[number];

type Environment = {
  publicOrigin: string;
  dealingRestOrigin: Origin;
  lightstreamerOrigin: string;
  paymentsOrigin: string;
  switchAccountOrigin: string;
  switchAccountClassicOrigin: string;
  apiOrigin: string;
  logoutOrigin: string;
  myIGUrl: string;
};

export const PROD_ENV: Environment = {
  publicOrigin: "https://www.ig.com",
  dealingRestOrigin: "https://deal.ig.com",
  lightstreamerOrigin: "https://wtp.ig.com",
  paymentsOrigin: "https://cpm.ig.com",
  switchAccountOrigin:
    "https://www.ig.com/validate/api/goto/wtpv2?secureToken={encryptedToken}&accountId={accountId}",
  switchAccountClassicOrigin:
    "https://www.ig.com/validate/api/goto/wtp?accountId={accountId}&cst={cstToken}",
  apiOrigin: "https://api.ig.com",
  logoutOrigin: "https://www.ig.com/welcome-page",
  myIGUrl: "https://www.ig.com/uk/myig/dashboard",
} as const;

export const DEMO_ENV: Environment = {
  publicOrigin: "https://demo.ig.com",
  dealingRestOrigin: "https://demo-deal.ig.com",
  lightstreamerOrigin: "https://demo-wtp.ig.com",
  paymentsOrigin: "https://demo-cpm.ig.com",
  switchAccountOrigin:
    "https://demo-deal.ig.com/validate/api/goto/wtpv2?secureToken={encryptedToken}&accountId={accountId}",
  switchAccountClassicOrigin:
    "https://demo-deal.ig.com/validate/api/goto/wtp?accountId={accountId}&cst={cstToken}",
  apiOrigin: "https://demo-api.ig.com",
  logoutOrigin: "https://demo.ig.com/welcome-page",
  myIGUrl: "https://demo.ig.com/uk/myig/dashboard",
} as const;

export const UAT_ENV: Environment = {
  publicOrigin: "https://web.ig.com",
  dealingRestOrigin: "https://web-deal.ig.com",
  lightstreamerOrigin: "https://web-wtp.ig.com",
  paymentsOrigin: "https://web-cpm.ig.com",
  switchAccountOrigin:
    "https://web.ig.com/validate/api/goto/wtpv2?secureToken={encryptedToken}&accountId={accountId}",
  switchAccountClassicOrigin:
    "https://web.ig.com/validate/api/goto/wtp?accountId={accountId}&cst={cstToken}",
  apiOrigin: "https://web-api.ig.com",
  logoutOrigin: "https://web.ig.com/welcome-page",
  myIGUrl: "https://web.ig.com/uk/myig/dashboard",
} as const;

export const TEST_ENV: Environment = {
  publicOrigin: "https://net.ig.com",
  dealingRestOrigin: "https://net-deal.ig.com",
  lightstreamerOrigin: "https://net-wtp.ig.com",
  paymentsOrigin: "https://net-cpm.ig.com",
  switchAccountOrigin:
    "https://net.ig.com/validate/api/goto/wtpv2?secureToken={encryptedToken}&accountId={accountId}",
  switchAccountClassicOrigin:
    "https://net.ig.com/validate/api/goto/wtp?accountId={accountId}&cst={cstToken}",
  apiOrigin: "https://net-api.ig.com",
  logoutOrigin: "https://net.ig.com/welcome-page",
  myIGUrl: "https://net.ig.com/uk/myig/dashboard",
} as const;

export const upgradeToLiveURL =
  "https://www.ig.com/uk/myig/dashboard?upgradeToLive";

const ENVIRONMENT = {
  PROD: PROD_ENV,
  DEMO: DEMO_ENV,
  UAT: UAT_ENV,
  TEST: TEST_ENV,
} as const;

function getEnvironment(env: string) {
  return (
    ENVIRONMENT[env.toUpperCase() as keyof typeof ENVIRONMENT] ||
    ENVIRONMENT.PROD
  );
}

export default getEnvironment;
