import React from "react";
import { Close } from "@ig-phoenix/icon";
import { FeedItem } from "../hooks/feed-items";

type GrowlProps = {
  children: React.ReactNode;
  growl: FeedItem;
  onClose: (growl: FeedItem) => void;
};

const Growl: React.FC<GrowlProps> = ({ children, growl, onClose }) => (
  <div
    className="growl"
    data-testid="growl"
    onAnimationEnd={({ animationName }) => {
      if (animationName === "growl") {
        onClose(growl);
      }
    }}
  >
    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
    <button
      type="button"
      className="growl_close"
      data-automation="close-growl"
      data-testid="close-growl"
      onClick={() => onClose(growl)}
    >
      <Close />
    </button>
    {children}
  </div>
);

export default Growl;
