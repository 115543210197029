/* istanbul ignore file */
// FLARGG
import { LightstreamerProvider } from "@ig-wpx/lightstreamer";
import React, { Suspense } from "react";
import { getEnvironment } from "@ig-wpx/utils";
import { StyleProvider, ThemeSet } from "@ig-phoenix/style-provider";
import Feed from "./Feed";
import AppProps from "./AppProps";
import { SessionProvider } from "../hooks/use-session";
import { Filter } from "../utils/constants";
import "../styles/light.css";
import "../styles/ig-feed.css";
import "../styles/ig-feed-item.css";
import "../styles/growl.css";

const App: React.FC<AppProps> = ({
  areIgTvNotificationsEnabled,
  children,
  client,
  igTvDisallowed,
  isFeedVisible,
  onLoadNextPage,
  selectIgTv,
  selectMarket,
  selectTab,
  shouldPlaySounds,
  twitterDisallowed,
}) => (
  <Suspense fallback="loading">
    <LightstreamerProvider
      client={client}
      origin={getEnvironment(client.env || "").lightstreamerOrigin}
    >
      <SessionProvider client={client}>
        <StyleProvider themeSet={ThemeSet.IGWebRebrand} colorScheme="Light" withFonts>
          <Feed
            areIgTvNotificationsEnabled={areIgTvNotificationsEnabled}
            defaultFilter={Filter.TWEETS}
            igTvDisallowed={igTvDisallowed}
            isFeedVisible={isFeedVisible ?? true}
            onLoadNextPage={onLoadNextPage || (() => {})}
            selectFilter={selectTab}
            selectIgTv={selectIgTv}
            selectMarket={selectMarket}
            shouldPlaySounds={shouldPlaySounds}
            twitterDisallowed={twitterDisallowed}
          />
          {children}
        </StyleProvider>
      </SessionProvider>
    </LightstreamerProvider>
  </Suspense>
);

export default App;
