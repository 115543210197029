import React from "react";
import FeedItemDate from "./FeedItemDate";
import { FeedAlert } from "../hooks/feed-items";
import { SelectMarketCallback, T } from "./AppProps";

type FeedItemPriceAlertProps = {
  data: FeedAlert;
  selectMarket: SelectMarketCallback;
  t: T;
};

const FeedItemPriceAlert: React.FC<FeedItemPriceAlertProps> = ({
  data: { content, metadata },
  selectMarket,
  t,
}) => {
  const wording = t(content.wording, { price: content.value });
  return (
    <div
      className="feed-item feed-item--price-alert"
      onClick={() => selectMarket(content.epic)}
      data-testid={`feed-item-${metadata.source}`}
    >
      <div className="feed-item_header">
        <span
          className="feed-item_icon transaction-state_icon transaction-state_icon--price-alert"
          data-automation="feed-item-price-alert-icon"
        />
        <span
          className="feed-item_header-title"
          data-automation="feed-item-price-alert-title"
          title={content.market}
        >
          {content.market}
        </span>
        <FeedItemDate timestamp={metadata.eventTimestamp} />
      </div>
      <div className="feed-item_section">
        <div
          className="feed-item_status"
          data-automation="feed-item-price-alert-status"
        >
          {wording}
        </div>
        {content.message}
      </div>
    </div>
  );
};

export default FeedItemPriceAlert;
