import React from "react";
import { useSession } from "../hooks/use-session";

type FeedItemDateProps = {
  timestamp: number;
};

const FeedItemDate: React.FC<FeedItemDateProps> = ({ timestamp }) => {
  const milliseconds = timestamp * 1000;
  const { clientLocale } = useSession();
  const locale = clientLocale.replace("_", "-");
  const dateFormatter = new Intl.DateTimeFormat(locale);
  const shortDate = dateFormatter.format(milliseconds);
  const isToday = shortDate === dateFormatter.format(Date.now());
  const formattedDateOrTime = isToday
    ? new Intl.DateTimeFormat(locale, {
        timeStyle: "short",
        // TODO: see if we can get the timezone for the site
        // timeZone: "UTC",
      }).format(milliseconds)
    : shortDate;
  const longDate = new Intl.DateTimeFormat(locale, {
    dateStyle: "long",
    timeStyle: "short",
  }).format(milliseconds);
  return (
    <div className="feed-item_header-age" data-automation="feed-item-age">
      <span title={longDate}>{formattedDateOrTime}</span>
    </div>
  );
};

export default FeedItemDate;
