import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useVirtualizer } from "@tanstack/react-virtual";
import FeedItem from "./FeedItem";
import FeedItemLoading from "./FeedItemLoading";
import { Filter } from "../utils/constants";
import { FeedItem as FeedItemType } from "../hooks/feed-items";
import {
  onLoadNextPageCallback,
  SelectIgTvCallback,
  SelectMarketCallback,
} from "./AppProps";

type FeedListProps = {
  filter: Filter;
  loadNextPage: (cursor: string) => any;
  isLoading: boolean;
  // eslint-disable-next-line react/require-default-props
  hasNextPage?: boolean;
  data: FeedItemType[];
  buffer: FeedItemType[];
  activateBuffer: (val: boolean) => any;
  clearBuffer: React.MouseEventHandler;
  // eslint-disable-next-line react/require-default-props
  useBuffer?: boolean;
  selectMarket: SelectMarketCallback;
  onLoadNextPage: onLoadNextPageCallback;
  selectIgTv: SelectIgTvCallback;
};

const FeedList: React.FC<FeedListProps> = ({
  filter,
  loadNextPage,
  isLoading,
  hasNextPage,
  data,
  buffer,
  activateBuffer,
  clearBuffer,
  useBuffer,
  selectMarket,
  onLoadNextPage,
  selectIgTv,
}) => {
  const { t } = useTranslation();
  const count = data.length;
  const bufferCount = buffer.length;
  const lastItem = data[count - 1];
  const cursor = lastItem && lastItem.metadata.id;

  const parentRef = useRef(null);
  const rowVirtualizer = useVirtualizer({
    count: hasNextPage ? count + 1 : count,
    getItemKey: (item) => data[item]?.metadata.id || "loading",
    getScrollElement: () => parentRef.current,
    estimateSize: () => 100,
    overscan: 5,
  });

  const renderedItems = rowVirtualizer.getVirtualItems();

  if (!useBuffer) {
    rowVirtualizer.scrollToIndex(0);
  }

  useEffect(() => {
    if (rowVirtualizer.scrollElement) {
      rowVirtualizer.scrollToIndex(0, { smoothScroll: false });
    }
  }, [rowVirtualizer, rowVirtualizer.scrollElement]);

  const [isLoadingNextPage, setIsLoadingNextPage] = useState(isLoading);

  useEffect(() => {
    const lastRenderedItem = renderedItems.at(-1);

    if (!lastRenderedItem) {
      return;
    }
    const shouldLoadNextPage =
      lastRenderedItem.index >= data.length - 1 &&
      hasNextPage &&
      !isLoadingNextPage;
    if (shouldLoadNextPage) {
      setIsLoadingNextPage(true);
      onLoadNextPage();
      loadNextPage(cursor);
    }
  }, [
    hasNextPage,
    data,
    isLoadingNextPage,
    renderedItems,
    onLoadNextPage,
    loadNextPage,
    cursor,
  ]);

  useEffect(() => setIsLoadingNextPage(false), [cursor]);

  return isLoading ? (
    <div className="ig-feed-spinner" data-testid="loading" />
  ) : (
    <>
      {!!bufferCount && (
        <div className="ig-streaming-list_buffer" onClick={clearBuffer}>
          <span className="ig-streaming-list_buffer-count">
            {t(bufferCount === 1 ? "new-item" : "new-items", {
              count: bufferCount,
            })}
          </span>
        </div>
      )}
      <div
        key={filter}
        ref={parentRef}
        style={{ height: "100%", overflow: "auto" }}
        onScroll={(event) => {
          const { scrollTop } = event.currentTarget;
          const shouldBufferItems = scrollTop > 50;
          if (useBuffer !== shouldBufferItems) {
            activateBuffer(shouldBufferItems);
          }
        }}
      >
        <div
          role="grid"
          style={{
            height: rowVirtualizer.getTotalSize(),
            position: "relative",
          }}
        >
          {renderedItems.map((virtualRow) => {
            const item = data[virtualRow.index];
            return (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                data-testid="feed-item"
                ref={rowVirtualizer.measureElement}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                {item && (
                  <FeedItem
                    data={item}
                    selectMarket={selectMarket}
                    selectIgTv={selectIgTv}
                  />
                )}
                {!item && hasNextPage && <FeedItemLoading />}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FeedList;
