/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/require-default-props */
import React from "react";
import twitter from "twitter-text";
import FeedItemDate from "./FeedItemDate";
import { FeedTwitter, TwitterMedia, TwitterUrl } from "../hooks/feed-items";
import { T } from "./AppProps";

type FeedItemTweetProps = {
  data: FeedTwitter;
  measure: () => void;
  t: T;
};

type LinkProps = {
  path?: string;
  className: string;
  children?: React.ReactNode;
  href?: string;
  title?: string;
};

const getFormattedTweetText = (
  text: string,
  /* istanbul ignore next */ urls: TwitterUrl[] = []
) => {
  const urlEntities = urls.map((entity) => ({
      display_url: entity.displayUrl,
      expanded_url: entity.expandedUrl,
      ...entity,
    }));

  return twitter.autoLink(twitter.htmlEscape(text), {
    targetBlank: true,
    // @ts-ignore
    urlEntities,
  });
};

const getPhoto = (media?: TwitterMedia[]) => {
  /* istanbul ignore else */
  if (media) {
    return media.find(
      ({ type }: { type?: string }) =>
        (type || /* istanbul ignore next */ "").toLowerCase() === "photo"
    );
  }
  /* istanbul ignore next */
  return null;
};

const Link: React.FC<LinkProps> = ({
  path,
  className,
  children,
  href,
  title,
}) => (
  <a
    className={className}
    target="_blank"
    rel="noopener noreferrer"
    title={title}
    href={href || `https://twitter.com/${path}`}
    onClick={/* istanbul ignore next */ (e) => e.stopPropagation()}
  >
    {children}
  </a>
);

const FeedItemTweet: React.FC<FeedItemTweetProps> = ({
  data: { content, metadata },
  t,
  measure,
}) => {
  const isRetweet = content.retweet;
  const { retweetingUser } = content;
  const username = content.user.userName;
  const formattedTweetText = getFormattedTweetText(content.text, content.urls);
  const photoMedia = getPhoto(content.media);
  const hasPhotoMedia = !!photoMedia;
  const tweetAuthor = content.user.screenName;
  const tweetId = content.id;
  const tweetLink = `https://twitter.com/${tweetAuthor}/status/${tweetId}`;

  return (
    <div
      className="feed-item feed-item--twitter"
      onClick={() => window.open(tweetLink)}
      data-testid={`feed-item-${metadata.source}`}
    >
      {isRetweet && (
        <div className="feed-item_header">
          <span className="feed-item_retweet-icon" />
          <Link
            path={retweetingUser.screenName}
            className="feed-item_retweet-author"
          >
            {retweetingUser.userName} {t("twitter.retweeted")}
          </Link>
        </div>
      )}
      <div className="feed-item_header">
        <Link
          path={tweetAuthor}
          className="feed-item_icon feed-item_icon--avatar"
        >
          <img src={content.user.profileImageUrl} alt={tweetAuthor} />
        </Link>
        <div className="feed-item_header-titles">
          <Link path={tweetAuthor} className="feed-item_header-title">
            {username}
          </Link>
          <Link path={tweetAuthor} className="feed-item_header-subtitle">
            @{tweetAuthor}
          </Link>
        </div>
        <FeedItemDate timestamp={metadata.eventTimestamp} />
      </div>
      <div
        className="feed-item_section feed-item_tweet"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: formattedTweetText }}
      />
      {hasPhotoMedia && (
        <Link
          href={photoMedia!.url}
          className="feed-item_section feed-item_link"
        >
          <img
            className="feed-item_media"
            onLoad={measure}
            src={photoMedia!.mediaUrl}
            alt={tweetAuthor}
          />
        </Link>
      )}
      <div className="feed-item_section twitter-footer">
        <Link
          path={`intent/tweet?in_reply_to=${tweetId}`}
          className="twitter-action twitter-action_reply"
          title={t("twitter.reply")}
        />
        <Link
          path={`intent/tweet?retweet=${tweetId}`}
          className="twitter-action twitter-action_retweet"
          title={t("twitter.retweet")}
        />
        <Link
          path={`intent/tweet?favorite=${tweetId}`}
          className="twitter-action twitter-action_favorite"
          title={t("twitter.favorite")}
        />
        <div className="feed-item_icon feed-item_icon--twitter-logo" />
      </div>
    </div>
  );
};

export default FeedItemTweet;
