/* eslint-disable react/require-default-props */
import React, { useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import FeedList from "./FeedList";
import useFeed from "../hooks/use-feed";
import { Filter } from "../utils/constants";
import {
  onLoadNextPageCallback,
  SelectFilterCallback,
  SelectIgTvCallback,
  SelectMarketCallback,
} from "./AppProps";
import FeedItem from "./FeedItem";
import UrgentAlert from "./UrgentAlert";
import Growl from "./Growl";

export type FeedProps = {
  areIgTvNotificationsEnabled: boolean;
  defaultFilter: Filter;
  igTvDisallowed?: boolean;
  isFeedVisible: boolean;
  selectFilter: SelectFilterCallback;
  onLoadNextPage: onLoadNextPageCallback;
  selectIgTv: SelectIgTvCallback;
  selectMarket: SelectMarketCallback;
  shouldPlaySounds: boolean;
  twitterDisallowed?: boolean;
};

type Tab = {
  className: string;
  l10n: string;
  filter: Filter;
};

const TABS: Tab[] = [
  {
    className: "tab-set_tab--notifications",
    l10n: "tabs.notifications",
    filter: Filter.NOTIFICATIONS,
  },
  {
    className: "tab-set_tab--twitter",
    l10n: "tabs.twitter",
    filter: Filter.TWEETS,
  },
  {
    className: "tab-set_tab--all",
    l10n: "tabs.all",
    filter: Filter.ALL,
  },
];

const Feed: React.FC<FeedProps> = ({
  areIgTvNotificationsEnabled,
  igTvDisallowed = false,
  isFeedVisible,
  twitterDisallowed = false,
  defaultFilter,
  selectFilter,
  onLoadNextPage,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectMarket = () => {},
  selectIgTv,
  shouldPlaySounds,
}) => {
  const {
    changeFilter,
    filter,
    growls,
    removeGrowl,
    removeUrgentAlert,
    urgentAlert,
    ...feedDataProps
  } = useFeed({
    areIgTvNotificationsEnabled,
    defaultFilter,
    isIgTvDisallowed: !!igTvDisallowed,
    isFeedVisible,
    shouldPlaySounds,
  });
  const tabs = twitterDisallowed
    ? TABS.filter(({ filter: f }) => f === Filter.NOTIFICATIONS)
    : TABS;
  const { t } = useTranslation();

  // TODO: Check this actually works
  useEffect(() => {
    selectFilter?.(filter);
  }, [filter, selectFilter]);

  const notificationsClassNames = classNames({
    notifications: true,
    "notifications--stacked": !isFeedVisible,
  });

  return (
    <div className="ig-feed">
      <div className={notificationsClassNames}>
        {urgentAlert && (
          <UrgentAlert
            alert={urgentAlert}
            onDismiss={removeUrgentAlert}
            data-testid="urgent-alert"
          />
        )}
        <div className="platform_growls growls" data-testid="growls">
          {growls.map((growl) => (
            <Growl growl={growl} key={growl.metadata.id} onClose={removeGrowl}>
              <FeedItem
                data={growl}
                selectMarket={selectMarket}
                selectIgTv={selectIgTv}
              />
            </Growl>
          ))}
        </div>
      </div>
      {isFeedVisible && (
        <>
          <ol className="tabs-nav">
            {tabs.map(({ className, l10n, filter: f }) => (
              <li
                key={f}
                className={classNames(className, "tabs-nav_tab", {
                  "tabs-nav_tab--active": f === filter,
                })}
              >
                <button
                  type="button"
                  className="tabs-nav_tab-btn"
                  data-testid={`tab-${f}`}
                  onClick={() => filter !== f && changeFilter(f)}
                >
                  {t(l10n)}
                </button>
              </li>
            ))}
          </ol>
          <div className="ig-feed_list-wrapper ig-feed_list-wrapper-alt">
            <FeedList
              {...feedDataProps}
              filter={filter}
              selectMarket={selectMarket}
              onLoadNextPage={onLoadNextPage}
              selectIgTv={selectIgTv}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Feed;
