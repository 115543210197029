const TWEET_FILTERS: string[] = ["TWITTER"];

const NOTIFICATION_FILTERS: string[] = [
  "PRICE_ALERT",
  "PRICE_CHANGE_ALERT",
  "INDICATOR_ALERT",
  "ORDER_NOTIFICATION",
  "IG_TV",
];

// eslint-disable-next-line no-shadow
export enum Filter {
  ALL = "all",
  NOTIFICATIONS = "notifications",
  TWEETS = "twitter",
}

// eslint-disable-next-line no-shadow
export enum UrgentAlertPrefix {
  SHOW = "!!",
  HIDE = "!-",
}

export const SOUND_PLAYING_SOURCES = [
  "PRICE_ALERT",
  "INDICATOR_ALERT",
  "PRICE_CHANGE_ALERT",
];

export const FILTERS = {
  [Filter.TWEETS]: {
    params: TWEET_FILTERS,
    streamingKeys: ({ siteId }: { siteId: string }): string[] => [
      `V2-M-MESSAGE_EVENT_HANDLER|TWITTER-${siteId}-FEED`,
    ],
  },
  [Filter.NOTIFICATIONS]: {
    params: NOTIFICATION_FILTERS,
    streamingKeys: ({
      accountId,
      isIgTvDisallowed,
    }: {
      accountId: string;
      isIgTvDisallowed: boolean;
    }): string[] =>
      [
        `V2-M-MESSAGE_EVENT_HANDLER|${accountId}-FEED`,
        !isIgTvDisallowed && "V2-M-MESSAGE_EVENT_HANDLER|IGTV-FEED",
      ].filter(Boolean) as string[],
  },
  [Filter.ALL]: {
    params: [...NOTIFICATION_FILTERS, ...TWEET_FILTERS],
    streamingKeys: ({
      accountId,
      siteId,
      isIgTvDisallowed,
    }: {
      accountId: string;
      siteId: string;
      isIgTvDisallowed: boolean;
    }): string[] => [
      ...FILTERS[Filter.TWEETS].streamingKeys({ siteId }),
      ...FILTERS[Filter.NOTIFICATIONS].streamingKeys({
        accountId,
        isIgTvDisallowed,
      }),
    ],
  },
};
