import App from "./components/App";
import AppProps from "./components/AppProps";
import initialiseI18n from "./i18n";

export default function Root(props: AppProps) {
  const { client } = props;
  initialiseI18n(
    `${client.clientLocale.replace("_", "-")}-${client.webSiteId}`
  );
  return <App {...props} />;
}
