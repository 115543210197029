/* eslint-disable react/require-default-props */
import React from "react";
import { useTranslation } from "react-i18next";
import FeedItemPriceAlert from "./FeedItemPriceAlert";
import FeedItemPriceChangeAlert from "./FeedItemPriceChangeAlert";
import FeedItemIndicatorAlert from "./FeedItemIndicatorAlert";
import FeedItemConfirm from "./FeedItemConfirm";
import FeedItemTv from "./FeedItemTv";
import FeedItemTweet from "./FeedItemTweet";
import {
  FeedAlert,
  FeedConfirm,
  FeedIgTv,
  FeedItem as FeedItemType,
  FeedTwitter,
} from "../hooks/feed-items";
import { SelectIgTvCallback, SelectMarketCallback } from "./AppProps";

type FeedItemProps = {
  data: FeedItemType;
  measure?: () => void;
  selectMarket: SelectMarketCallback;
  selectIgTv: SelectIgTvCallback;
};

const FeedItem: React.FC<FeedItemProps> = ({
  data,
  measure,
  selectMarket,
  selectIgTv,
}) => {
  const { t } = useTranslation();
  const measureFn = measure || /* istanbul ignore next */ (() => {});

  switch (data.metadata.source) {
    case "PRICE_ALERT":
      return (
        <FeedItemPriceAlert
          data={data as FeedAlert}
          selectMarket={selectMarket}
          t={t}
        />
      );
    case "PRICE_CHANGE_ALERT":
      return (
        <FeedItemPriceChangeAlert
          data={data as FeedAlert}
          selectMarket={selectMarket}
          t={t}
        />
      );
    case "INDICATOR_ALERT":
      return (
        <FeedItemIndicatorAlert
          data={data as FeedAlert}
          selectMarket={selectMarket}
        />
      );
    case "ORDER_NOTIFICATION":
      return (
        <FeedItemConfirm
          data={data as FeedConfirm}
          selectMarket={selectMarket}
          t={t}
        />
      );
    case "TWITTER":
      return (
        <FeedItemTweet data={data as FeedTwitter} measure={measureFn} t={t} />
      );
    case "IG_TV":
      return (
        <FeedItemTv
          data={data as FeedIgTv}
          measure={measureFn}
          selectIgTv={selectIgTv}
          t={t}
        />
      );
    /* istanbul ignore next */
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};

export default FeedItem;
