import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ICU from "i18next-icu";
import Fetch from "i18next-fetch-backend";
import { resolveTranslations } from "@ig-wpx/utils";

function initialiseI18n(lng = "en") {
  return (
    i18n
      .use(Fetch)
      .use(ICU)
      .use(initReactI18next)
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: "en",
        supportedLngs: [
          "de",
          "en",
          "en-GB-aus",
          "en-GB-igs",
          "en-US",
          "es",
          "fr",
          "it",
          "ja",
          "nl",
          "no",
          "pt",
          "sv",
          "zh-CN",
          "zh-TW",
        ],
        debug: false,
        lng,
        backend: {
          loadPath: resolveTranslations("@ig-wpx/feed"),
        },
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      })
  );
}

export default initialiseI18n;
